import {
  Cell,
  Text,
  Card,
  Loader,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  WixDesignSystemProvider,
  Image,
  ToggleSwitch,
  Layout,
  FormField,
  Input,
  NumberInput,
  Box,
  SkeletonCircle,
  SkeletonLine,
  SkeletonGroup,
  SectionHelper,
  VariableInput,
  PopoverMenu,
  TextButton,
  InputArea,
  Button,
  DropdownBase,
  StatisticsWidget,
  ThemeProvider,
  IconButton,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import React, { useEffect } from "react";
import { ReactTagManager } from "react-gtm-ts";
import { dashboard } from "@wix/dashboard";
import { createClient } from "@wix/sdk";
import * as Icons from "@wix/wix-ui-icons-common";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

var template = {
  isEnabled: false,
  percentage: 1,
  conversionRate: 50,
  goalName: "remove CO₂ from the atmosphere",
  businessName: "We",
  message:
    "{{business.name}} contributed {{percentage}}% of your purchase to {{goal.name}}.",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const [client, setClient] = React.useState(null as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isEdited, setIsEdited] = React.useState(false);
  const [site, setSite] = React.useState(null as any);
  const [config, setConfig] = React.useState({
    isEnabled: false,
    percentage: 1,
    conversionRate: 50,
    goalName: "remove CO₂ from the atmosphere",
    businessName: "We",
    message:
      "{{business.name}} contributed {{percentage}}% of your purchase to {{goal.name}}.",
  });
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getSettings();
    getSite();
  }, []);

  const BASE_URL = `https://certifiedcode.wixsite.com/climate/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setInstanceData(data);
      })
      .catch(() => {});
  }

  function getSettings() {
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
      })
      .catch(() => {});
  }

  function getSite() {
    fetch(BASE_URL + "/site", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSite(data);
      })
      .catch(() => {});
  }

  function updateSettings() {
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify(config),
    })
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        getSite();
      })
      .catch(() => {});
  }

  useEffect(() => {
    try {
      if (inIframe()) {
        var c = createClient({
          host: dashboard.host(),
          auth: dashboard.auth(),
          modules: {
            dashboard,
          },
        });
        setClient(c);
      }
    } catch (error) {
      throw error;
    }
  }, []);

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    } else {
      setIsEdited(true);
    }
  }, [config]);

  const Statistics = () => {
    const weekUS = [
      {
        value: "999",
        description: "Total contributions",
        // percentage: 0,
        // descriptionInfo: 'Shares in 7 days',
        // onClick: () => { },
      },
      {
        value: "0",
        description: "CO₂ removed",
        // percentage: 0,
        // descriptionInfo: 'Shares in 7 days',
        // onClick: () => { },
      },
    ];

    return (
      <Card>
        <Card.Header title={"Contributions performance"} />
        <Card.Divider />
        <Card.Content>
          <StatisticsWidget items={weekUS} />
        </Card.Content>
      </Card>
    );
  };

  const MessageInput = () => {
    const bodyRef = React.useRef();
    const [value, setValue] = React.useState(
      "{{business.name}} contributed {{percentage}}% of your purchase to remove CO₂ from the atmosphere."
    );

    useEffect(() => {
      if (value !== config.message) {
        setConfig({ ...config, message: value });
      }
    }, [value]);

    const variables = [
      {
        key: "business.name",
        value: "Business name",
      },
      {
        key: "percentage",
        value: "Percentage",
      },
    ];

    const onChange = (e: any) => {
      setValue(e);
    };

    const insertVariable = (variable: any) =>
      // @ts-ignore
      bodyRef.current.insertVariable(variable.key);

    return (
      <Box gap="10px">
        <FormField label="Write a message to show at checkout">
          <VariableInput
            initialValue={
              "{{business.name}} contributed {{percentage}}% of your purchase to remove CO₂ from the atmosphere."
            }
            onChange={(e: any) => onChange(e)}
            placeholder="Pick variables and/ or enter strings..."
            variableParser={(value: any) => {
              const variable = variables.find((item) => item.key === value);

              return variable ? variable.value : false;
            }}
            // @ts-ignore
            ref={bodyRef}
            rows={3}
          />
        </FormField>
        <PopoverMenu triggerElement={<TextButton>Insert Variable</TextButton>}>
          {variables.map((variable) => (
            <PopoverMenu.MenuItem
              text={variable.value}
              onClick={() => insertVariable(variable)}
            />
          ))}
        </PopoverMenu>
      </Box>
    );
  };

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=30dc997f-2d12-4c4b-a0e4-a3783d87c4ec&redirectUrl=https://certifiedcode.wixsite.com/climate/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page>
        <Page.Header
          title="Climate: Orders Create Impact"
          subtitle="Climate is a free app that helps you to show your customers how you are contributing to the community."
        />
        <Page.Content>
          <Layout>
            <Cell span={12}>
              <Card>
                <Card.Header title={"Program details"} />
                <Card.Divider />
                <Card.Content>
                  <StatisticsWidget
                    alignItems="start"
                    items={[
                      {
                        value: `${site?.settings?.percentage || "-"}%`,
                        description: "of order value goes to contribute",
                      },
                      {
                        value: `$${site?.contributions?.sumAmount || "-"}`,
                        description: "donations",
                      },
                      {
                        value: `${
                          (site?.contributions?.sumAmount || 0) /
                          (site?.settings?.conversionRate || 1)
                        }`,
                        description: `${
                          site?.settings?.goalName || "Goal not set"
                        }`,
                      },
                    ]}
                  />
                </Card.Content>
              </Card>
            </Cell>
            <Cell span={8}>
              <Card>
                <Card.Header
                  title="Show Climate at Checkout"
                  suffix={
                    <ToggleSwitch
                      onChange={async () => {
                        const data = await client.dashboard.addSitePlugin(
                          "02c988e2-72df-4aed-8da3-6622ca968ebe",
                          {
                            placement: {
                              appDefinitionId: "30dc997f-2d12-4c4b-a0e4-a3783d87c4ec",
                              widgetId: "a128c305-f971-46a6-a931-4ed5ba2af6c8",
                              slotId: "checkout:summary:after",
                            }
                            ,
                          }
                        );
                        console.log(data);
                      }}
                    />
                  }
                />
                <Card.Content>
                  <Box gap="SP2" direction="vertical">
                    <TextButton
                      prefixIcon={<Icons.RefreshSmall />}
                      onClick={() => {
                        setConfig(template);
                      }}
                    >
                      Reset to default
                    </TextButton>
                    <FormField label="What is your business name?">
                      <Input
                        value={config.businessName}
                        onChange={(e) => {
                          setConfig({
                            ...config,
                            businessName: e.target.value,
                          });
                        }}
                      />
                    </FormField>
                    <FormField label="How many % do you want to contribute?">
                      <NumberInput
                        suffix={<Input.Affix>%</Input.Affix>}
                        value={config.percentage}
                        max={100}
                        min={1}
                        onChange={(e) => {
                          if (e) {
                            setConfig({ ...config, percentage: e });
                          }
                        }}
                      />
                    </FormField>
                    <FormField label="Write a message to show at checkout">
                      <InputArea
                        value={config.message}
                        onChange={(e) => {
                          setConfig({ ...config, message: e.target.value });
                        }}
                      />
                    </FormField>
                    <FormField label="What is your goal name?">
                      <InputArea
                        value={config.goalName}
                        onChange={(e) => {
                          setConfig({ ...config, goalName: e.target.value });
                        }}
                      />
                    </FormField>
                    <Button
                      onClick={() => updateSettings()}
                      disabled={!isEdited}
                    >
                      Save
                    </Button>
                    {/* <MessageInput
                    /> */}
                  </Box>
                </Card.Content>
              </Card>
            </Cell>
            <Cell span={4}>
              <Box direction="vertical" gap="SP4">
                <Card>
                  <Card.Header title="Preview" />
                  <Card.Content>
                    {isLoading ? (
                      <Box gap="SP2" direction="horizontal">
                        <SkeletonGroup skin="light">
                          <Layout>
                            <Cell>
                              <Layout>
                                <Cell span={2}>
                                  <SkeletonCircle diameter="32px" />
                                </Cell>
                                <Cell span={10}>
                                  <Box direction="vertical">
                                    <SkeletonLine marginBottom="5px" />
                                    <SkeletonLine marginBottom="5px" />
                                  </Box>
                                </Cell>
                              </Layout>
                            </Cell>
                          </Layout>
                        </SkeletonGroup>
                      </Box>
                    ) : (
                      <Box gap="SP2" direction="horizontal">
                        <IconButton skin="light">
                          <Icons.GlobeSmall />
                        </IconButton>
                        <Text>
                          {(config.message || "")
                            .replace("{{business.name}}", config.businessName)
                            .replace(
                              "{{percentage}}",
                              config.percentage.toString()
                            )
                            .replace("{{goal.name}}", config.goalName)}
                        </Text>
                      </Box>
                    )}
                  </Card.Content>
                </Card>
                {config.isEnabled ? (
                  <SectionHelper appearance="success">
                    Once you made the changes, it reflects on live checkout
                    immediately.
                  </SectionHelper>
                ) : (
                  <SectionHelper appearance="preview">
                    As you have disabled Climate, the content above will not
                    show at checkout.{" "}
                  </SectionHelper>
                )}
              </Box>
            </Cell>
            <Cell span={12}>
              <Text size="tiny">
                We recommend donate via{" "}
                <a href="https://stripe.com/en-us/climate/contribute">
                  Stripe Climate
                </a>
                . Certified Code created this app and commited to be free
                forever.
              </Text>
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
